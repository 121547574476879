import React from 'react';

import { cn } from '../../lib/utils.lib';

import './Button.css';

interface P {
  text?: string;
  className?: string;
  title?: string;
  onClick?: () => void;
}

export class Button extends React.Component<P> {
  render() {
    const { text, title, className } = this.props;
    const btnClassName = cn(className, 'LandingButton');
    return (
      <button className={btnClassName} type="button" title={title} onClick={() => this.onClick()}>
        {text}
      </button>
    );
  }

  // event handlers

  onClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }
}
