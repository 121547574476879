import React from 'react';

import { cn } from '../../lib/utils.lib';
import { formatStreamType } from '../../services/fmt.service';
import {
  getHlsUrl,
  getPlayerState,
  PlayerType,
  setPlayerType,
  setStreamType,
  startPlaying,
  StreamType,
} from '../../services/player.service';
import { getSession, isSessionActive, isSessionLive } from '../../services/session.service';
import { TabList } from './TabList';

import './Video.css';

interface P {
  className?: string;
}

const PLAYER_TABS = [
  { code: PlayerType.webrtc, title: 'WebRTC' },
  { code: PlayerType.mse, title: 'MSE-LD' },
  { code: PlayerType.hls, title: 'HLS' },
];

const STREAM_TABS = [
  { code: StreamType.source, title: formatStreamType(StreamType.source) },
  { code: StreamType.mbr, title: formatStreamType(StreamType.mbr) },
  { code: StreamType.nvr, title: formatStreamType(StreamType.nvr) },
];

export class Video extends React.Component<P> {
  render() {
    // We need "stats-box" in DOM otherwise WebRtcPlayer will fail
    // (looks like a bug in flussonic-webrtc-player)
    const { className } = this.props;
    const { isPlaying, isPlayerLoading, streamType, playerType } = getPlayerState();
    const divClassName = cn(
      'PlayerVideo',
      className,
      isPlaying && '_playing',
      isPlayerLoading && '_loading',
    );
    const isLive = isSessionLive();
    const isActive = isSessionActive();
    return (
      <div className={divClassName}>
        <TabList
          className="PlayerVideo_stream"
          items={STREAM_TABS}
          tab={streamType}
          disabled={!isLive || !isActive}
          onChange={(tab) => this.onStreamTabChange(tab as StreamType)}
        />
        <TabList
          className="PlayerVideo_tabs"
          items={PLAYER_TABS}
          tab={playerType}
          disabled={!isLive || !isActive || streamType === StreamType.nvr}
          onChange={(tab) => this.onPlayerTabChange(tab as PlayerType)}
        />
        <div className="PlayerVideo_overlay">
          {this.renderWebrtcVideo()}
          {this.renderMseVideo()}
          {this.renderHlsIframe()}
        </div>
        {this.renderPlayButton()}
        <div className="stats-box d-none"></div>
      </div>
    );
  }

  // event handlers

  onPlay() {
    const { streamType, playerType } = getPlayerState();
    startPlaying(streamType, playerType);
  }

  onPlayerTabChange(nextPlayerType: PlayerType) {
    const { playerType } = getPlayerState();
    if (nextPlayerType !== playerType) {
      setPlayerType(nextPlayerType);
    }
  }

  onStreamTabChange(nextStreamType: StreamType) {
    const { streamType } = getPlayerState();
    if (nextStreamType !== streamType) {
      setStreamType(nextStreamType);
    }
  }

  // render helpers

  renderPlayButton() {
    const { isPlaying, isPlayerLoading: isPlayingStub } = getPlayerState();
    if (isPlaying || isPlayingStub) {
      return null;
    }
    return (
      <svg
        className="PlayerVideo_button"
        xmlns="http://www.w3.org/2000/svg"
        width="130"
        height="130"
        viewBox="0 0 130 130"
        onClick={() => this.onPlay()}
      >
        <g transform="translate(-763 -505)">
          <circle cx="65" cy="65" r="65" transform="translate(763 505)" fill="#f3f3f3" />
          <g>
            <path
              d="M25.821,8.855a6,6,0,0,1,10.358,0l20.54,35.116A6,6,0,0,1,51.54,53H10.46a6,6,0,0,1-5.179-9.029Z"
              transform="translate(863.5 539.5) rotate(90)"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
    );
  }

  renderPlayer() {
    const { isPlaying } = getPlayerState();
    return (
      <div className={cn(!isPlaying && 'd-none')}>
        {this.renderWebrtcVideo()}
        {this.renderMseVideo()}
        {this.renderHlsIframe()}
      </div>
    );
  }

  renderWebrtcVideo() {
    const { isPlaying, playerType } = getPlayerState();
    if (!isPlaying || playerType !== PlayerType.webrtc) {
      return null;
    }
    return <video id="videoPlayerWebrtc" autoPlay controls></video>;
  }

  renderMseVideo() {
    const { isPlaying, playerType } = getPlayerState();
    if (!isPlaying || playerType !== PlayerType.mse) {
      return null;
    }
    return <video id="videoPlayerMse"></video>;
  }

  renderHlsIframe() {
    const { isPlaying, playerType } = getPlayerState();
    const session = getSession();
    if (!session || !isPlaying || playerType !== PlayerType.hls) {
      return null;
    }
    const url = getHlsUrl();
    return <iframe title="Video Player" src={url}></iframe>;
  }
}
