import { MSuccess } from '../components/modals/MSuccess';
import { MTicket } from '../components/modals/MTicket';
import { config } from '../config';
import { getLsItem, LSK_SESSION_RATING_MAP, setLsItem } from './ls.service';
import { showModal } from './modal.service';
import { getUrlParam, setUrlParam } from './router.service';
import * as sdk from './sdk.service';
import { updateUi } from './ui.service';

const NO_SESSION = 'NO_SESSION';

let session: sdk.Session | undefined;
let pollingTimer: number | undefined;

interface SessionRatingMap {
  [key: string]: number;
}

export async function fetchSession() {
  const token = getUrlParam('token');
  if (!token) {
    return undefined;
  }
  try {
    session = await sdk.getSession(token);
  } catch {
    setUrlParam('token', '');
    return undefined;
  }
  updateUi();
  startSessionPolling();
  return session;
}

export async function createSession() {
  session = await sdk.createSession();
  setUrlParam('token', session.token);
  startSessionPolling();
  return session;
}

export async function startSession() {
  if (!session) {
    throw new Error(NO_SESSION);
  }
  session = await sdk.startSession(session.token);
  return session;
}

export async function activateSession() {
  if (!session) {
    throw new Error(NO_SESSION);
  }
  session = await sdk.activateSession(session.token);
  return session;
}

export function getSession() {
  return session;
}

export function getSecondsTotal() {
  if (!session) {
    return config.defaultSessionTtlInSeconds;
  }
  return session.ttlInSeconds;
}

export function getSecondsLeft() {
  if (!session) {
    return config.defaultSessionTtlInSeconds;
  }
  if (!session.startedAt) {
    return session.ttlInSeconds;
  }
  const secondsPassed = Math.round((Date.now() - session.startedAt) / 1000);
  return Math.max(0, session.ttlInSeconds - secondsPassed);
}

export function isSessionLive() {
  return Boolean(session && getSecondsLeft() > 0);
}

export function isSessionStarted() {
  return Boolean(session && session.startedAt);
}

export function isSessionActive() {
  return Boolean(session && session.isActive);
}

export function stopSessionPolling() {
  if (pollingTimer) {
    window.clearInterval(pollingTimer);
    pollingTimer = undefined;
  }
}

export async function rateSession() {
  if (!session) {
    return;
  }
  const token = session.token;
  const sessionRatingMap = getLsItem<SessionRatingMap>(LSK_SESSION_RATING_MAP) || {};
  if (sessionRatingMap[token]) {
    return;
  }
  const rating = await showModal<number | undefined>(MTicket, { session });
  if (!rating) {
    return;
  }
  sessionRatingMap[token] = rating;
  setLsItem(LSK_SESSION_RATING_MAP, sessionRatingMap);
  showModal(MSuccess);
}

// private

function startSessionPolling() {
  if (!session) {
    return;
  }
  if (session.isActive) {
    return;
  }
  const token = session.token;
  pollingTimer = window.setInterval(async () => {
    try {
      const freshSession = await sdk.getSession(token);
      session = freshSession;
    } catch {
      stopSessionPolling();
      return;
    }
    if (!session) {
      stopSessionPolling();
      return;
    }
    if (session.isActive) {
      stopSessionPolling();
      updateUi();
    }
  }, 2000);
}
