import React from 'react';

import { formatStreamType } from '../../services/fmt.service';
import {
  getPlayerState,
  stopPlaying,
  stopPublishing,
  StreamType,
} from '../../services/player.service';
import { rateSession, stopSessionPolling } from '../../services/session.service';
import { updateUi } from '../../services/ui.service';
import { Source } from './Source';
import { Step } from './Step';
import { Video } from './Video';

import './Player.css';

const STEPS = [
  {
    button: {
      text: `${formatStreamType(StreamType.source)} Stream`,
      streamType: StreamType.source,
    },
    item: {
      title: 'WebRTC Ingress Server',
      url:
        'https://flussonic.com/doc/live-streaming/using-webrtc-for-video-publication-to-flussonic-media-server?utm_source=webrtc&utm_medium=referral',
      text: (
        <React.Fragment>
          live source_stream &#123;
          <br />
          &nbsp;&nbsp;max_bitrate 2500;
          <br />
          &nbsp;&nbsp;min_bitrate 100;
          <br />
          &nbsp;&nbsp;abr_stepup 30;
          <br />
          &nbsp;&nbsp;abr_correction 300;
          <br />
          &nbsp;&nbsp;abr_loss_lower 2;
          <br />
          &nbsp;&nbsp;abr_loss_upper 10;
          <br />
          &nbsp;&nbsp;abr_stepdown 50;
          <br />
          &nbsp;&nbsp;abr_cycles 5;
          <br />
          &nbsp;&nbsp;transport tcp;
          <br />
          &#125;
        </React.Fragment>
      ),
    },
  },
  {
    button: { text: `${formatStreamType(StreamType.mbr)} Stream`, streamType: StreamType.mbr },
    item: {
      title: 'Multibitrate Transcoding and Logo Burn-in Server',
      url: 'https://flussonic.com/doc/transcoder?utm_source=webrtc&utm_medium=referral',
      text: (
        <React.Fragment>
          stream mbr_stream &#123;
          <br />
          &nbsp;&nbsp;url m4s://step01/source_stream;
          <br />
          &nbsp;&nbsp;transcoder
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;vb=300k vcodec=h264 size=-1x144
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;logo=@logo.png@10:10
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;burn=text@144p:br:10:10
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;preset=medium profile=baseline
          <br />
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;vb=900k vcodec=h264 size=-1x480
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;logo=@logo.png@10:10
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;burn=text@480p:br:10:10
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;preset=medium profile=baseline
          <br />
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;vb=1500k vcodec=h264 size=-1x960
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;logo=@logo.png@10:10
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;burn=text@960p:br:10:10
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;preset=medium profile=baseline
          <br /><br />
          &nbsp;&nbsp;&nbsp;&nbsp;hw=cpu ab=copy gop=25;
          <br />
          &#125;
        </React.Fragment>
      ),
    },
  },
  {
    button: { text: `${formatStreamType(StreamType.nvr)} Stream`, streamType: StreamType.nvr },
    item: {
      title: 'Archive & Timeshift Server',
      url:
        'https://flussonic.com/doc/digital-video-recording-dvr?utm_source=webrtc&utm_medium=referral',
      hint: 'hint: use rewind controls in player',
      text: (
        <React.Fragment>
          stream nvr_stream &#123;
          <br />
          &nbsp;&nbsp;url m4s://step02/mbr_stream;
          <br />
          &nbsp;&nbsp;dvr/storage 5m;
          <br />
          &#125;
          <br />
          <br />
          stream timeshifted_stream &#123;
          <br />
          &nbsp;&nbsp;url timeshift://nvr_stream/30;
          <br />
          &#125;
        </React.Fragment>
      ),
    },
  },
];

export class Player extends React.Component {
  render() {
    return (
      <div className="Player">
        <div className="Player_wrapper">
          <div className="Player_source">
            <Source onSessionEnd={() => this.onSessionEnd()} />
            <a className="Player_link" href="/">
              About WebRTC Demo
            </a>
          </div>
          <div className="Player_video">
            <div className="Player_steps">
              {STEPS.map((x, index) => (
                <Step key={index} item={{ number: index + 1, ...x.item }} button={x.button} />
              ))}
            </div>
            <Video />
          </div>
        </div>
      </div>
    );
  }

  // event handlers

  async onSessionEnd() {
    const { isPublishing, isPlaying } = getPlayerState();
    if (isPublishing) {
      stopPublishing();
    }
    if (isPlaying) {
      stopPlaying();
    }
    updateUi();
    stopSessionPolling();
    rateSession();
  }
}
