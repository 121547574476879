import FlussonicMsePlayer from '@flussonic/flussonic-mse-player';
import { Player, Publisher, PUBLISHER_EVENTS } from '@flussonic/flussonic-webrtc-player';

import { sleep } from '../lib/utils.lib';
import { Session } from './sdk.service';
import {
  activateSession,
  getSession,
  isSessionActive,
  isSessionLive,
  isSessionStarted,
  startSession,
} from './session.service';
import { updateUi } from './ui.service';

export interface PlayerState {
  isPublishing: boolean;
  isPlaying: boolean;
  isPlayerLoading: boolean;
  streamType: StreamType;
  playerType: PlayerType;
}

export enum StreamType {
  source = 'source',
  mbr = 'mbr',
  nvr = 'nvr',
}

export enum PlayerType {
  webrtc = 'webrtc',
  mse = 'mse',
  hls = 'hls',
}

let state: PlayerState = {
  isPublishing: false,
  isPlaying: false,
  isPlayerLoading: false,
  streamType: StreamType.source,
  playerType: PlayerType.webrtc,
};

let publisher: any;
let webrtcPlayer: any;
let msePlayer: any;

export function getPlayerState() {
  return state;
}

export async function startPublishing() {
  if (!isSessionLive()) {
    return;
  }
  if (!isSessionStarted()) {
    await startSession();
  }
  const session = getSession();
  if (!session) {
    return;
  }
  if (state.isPublishing) {
    return;
  }
  state.isPublishing = true;
  if (!state.isPlaying) {
    state.isPlayerLoading = true;
  }
  updateUi();
  await sleep();
  initPublisher(session);
}

export async function stopPublishing() {
  destroyPublisher();
  await sleep();
  state.isPublishing = false;
  updateUi();
}

export async function startPlaying(streamType: StreamType, playerType: PlayerType) {
  if (!isSessionLive()) {
    return;
  }
  if (!isSessionActive()) {
    return;
  }
  const session = getSession();
  if (!session) {
    return;
  }
  state.isPlayerLoading = true;
  if (state.isPlaying) {
    await stopPlaying();
    await sleep();
  }
  state.isPlaying = true;
  state.streamType = streamType;
  state.playerType = playerType;
  updateUi();
  await sleep();
  initPlayer(session, streamType, playerType);
  if (playerType === PlayerType.hls) {
    // avoid appearing of the play button in IFRAME
    await sleep(1000);
  }
  state.isPlayerLoading = false;
  updateUi();
}

export async function stopPlaying() {
  destroyPlayer();
  await sleep();
  state.isPlaying = false;
  updateUi();
}

export async function setStreamType(streamType: StreamType) {
  const playerType = streamType === StreamType.nvr ? PlayerType.hls : PlayerType.webrtc;
  if (state.isPlaying) {
    startPlaying(streamType, playerType);
  } else {
    state.streamType = streamType;
    state.playerType = playerType;
    updateUi();
  }
}

export async function setPlayerType(playerType: PlayerType) {
  if (state.streamType === StreamType.nvr) {
    return;
  }
  if (state.isPlaying) {
    startPlaying(state.streamType, playerType);
  } else {
    state.playerType = playerType;
    updateUi();
  }
}

export function getHlsUrl() {
  const session = getSession();
  if (!session) {
    return undefined;
  }
  let url = `https://${session.videoServerHost}/${state.streamType}_${session.token}/embed.html`;
  if (state.streamType === StreamType.nvr) {
    url += '?ago=300';
  }
  return url;
}

// private

function initPublisher(session: Session) {
  publisher = new Publisher(
    `https://${session.videoServerHost}/source_${session.token}`,
    {
      preview: document.getElementById('videoCamera'),
      previewOptions: {
        autoplay: true,
        controls: false,
        muted: true,
      },
      constraints: {
        video: {
          width: { min: 1024, ideal: 1280, max: 1920 },
          height: { min: 576, ideal: 720, max: 1080 }
        },
        audio: true,
      },
      // whipwhap: false,
    },
    false, // log to console internal debug messages
  );
  publisher.on(PUBLISHER_EVENTS.STREAMING, async () => {
    if (!state.isPlaying && state.isPlayerLoading && !publisher.$streamingHandled) {
      publisher.$streamingHandled = true;
      await sleep(1000);
      await activateSession();
      startPlaying(state.streamType, state.playerType);
    }
  });
  publisher.start();
}

function destroyPublisher() {
  if (publisher) {
    publisher.stop();
    publisher = undefined;
  }
}

function initPlayer(session: Session, streamType: StreamType, playerType: PlayerType) {
  if (playerType === PlayerType.webrtc) {
    webrtcPlayer = new Player(
      document.getElementById('videoPlayerWebrtc'),
      `https://${session.videoServerHost}/${streamType}_${session.token}`,
      {
        whipwhap: true,
      },
      false, // log to console internal debug messages
    );
    webrtcPlayer.play();
  }
  if (playerType === PlayerType.mse) {
    msePlayer = new FlussonicMsePlayer(
      document.getElementById('videoPlayerMse'),
      `wss://${session.videoServerHost}/${streamType}_${session.token}/mse_ld`,
    );
    msePlayer.play();
  }
}

function destroyPlayer() {
  if (webrtcPlayer) {
    webrtcPlayer.stop();
    webrtcPlayer = undefined;
  }
  if (msePlayer) {
    msePlayer.stop();
    msePlayer = undefined;
  }
}
