import React from 'react';

import { cn } from '../../lib/utils.lib';

import './TabList.css';

interface P {
  className?: string;
  tab: string;
  items: TabItem[];
  disabled?: boolean;
  onChange?: (tab: string) => void;
}

interface TabItem {
  code: string;
  title: string;
}

export class TabList extends React.Component<P> {
  render() {
    const { items, className, disabled } = this.props;
    return (
      <ul className={cn(className, 'TabList', disabled && '_disabled')}>
        {items.map((item) => this.renderItem(item))}
      </ul>
    );
  }

  // event handlers

  onItemClick(tab: string) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(tab);
    }
  }

  // render helpers

  renderItem(item: TabItem) {
    const { tab } = this.props;
    const { code, title } = item;
    const className = cn('TabList_item', tab === code ? '_active' : '');
    return (
      <li className={className} key={code} onClick={() => this.onItemClick(code)}>
        <span className="TabList_itemText">{title}</span>
      </li>
    );
  }
}
