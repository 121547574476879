/* eslint-disable */

import React from 'react';

interface P {
  className?: string;
  text?: string;
  onClick?: () => void;
  children?: any;
}

export class VoidLink extends React.Component<P> {
  render() {
    const { className, text } = this.props;
    return (
      <a href="#" className={className} onClick={(e) => this.onClick(e)}>
        {text ? text : this.props.children}
      </a>
    );
  }

  // event handlers

  onClick(e: any) {
    const { onClick } = this.props;
    e.preventDefault();
    if (onClick) {
      onClick();
    }
  }
}
