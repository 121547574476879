type Updater = () => void;

let updater: Updater | undefined;

export function registerUiUpdater(f: Updater) {
  updater = f;
}

export function updateUi() {
  if (updater) {
    updater();
  }
}

export function scrollToTop() {
  window.scrollTo(0, 0);
}

export function scrollToElement(selector: string) {
  const domNode = document.querySelector(selector);
  if (domNode) {
    const rect = domNode.getBoundingClientRect();
    window.scroll({ top: window.pageYOffset + rect.top, behavior: 'smooth' });
  }
}
