import React from 'react';

import { cn } from '../../lib/utils.lib';
import { copyTextToClipboard } from '../../services/clipboard.service';
import { getPlayerState, startPublishing, stopPublishing } from '../../services/player.service';
import {
  createSession,
  getSecondsLeft,
  getSecondsTotal,
  getSession,
  isSessionLive,
  rateSession,
} from '../../services/session.service';
import { ProgressBar } from './ProgressBar';
import { TabList } from './TabList';

import './Source.css';

interface P {
  className?: string;
  onSessionEnd?: () => void;
}

interface S {
  type: string;
  secondsLeft: number;
  justCopied: boolean;
}

const TABS = [
  { code: 'local', title: 'Local' },
  { code: 'remote', title: 'Remote' },
];

export class Source extends React.Component<P, S> {
  timer: number | undefined;

  constructor(props: P) {
    super(props);
    this.state = {
      type: 'local',
      secondsLeft: getSecondsLeft(),
      justCopied: false,
    };
  }

  render() {
    const { className } = this.props;
    const { type, secondsLeft } = this.state;
    const { isPublishing } = getPlayerState();
    return (
      <div className={cn('PlayerSource', className, !this.typeIsLocal() && '_remote')}>
        <h2 className="PlayerSource_title">WebRTC Source</h2>
        <TabList
          className="PlayerSource_tabs"
          items={TABS}
          tab={type}
          disabled={isPublishing}
          onChange={(tab) => this.onTabChange(tab)}
        />
        <ProgressBar
          className="PlayerSource_progress"
          secondsTotal={getSecondsTotal()}
          secondsLeft={secondsLeft}
        />
        {this.typeIsLocal() ? this.renderLocal() : this.renderRemote()}
      </div>
    );
  }

  componentDidMount() {
    const { onSessionEnd } = this.props;
    this.timer = window.setInterval(() => {
      const secondsLeft = getSecondsLeft();
      if (secondsLeft !== this.state.secondsLeft) {
        this.setState({ secondsLeft });
        if (secondsLeft === 0 && onSessionEnd) {
          onSessionEnd();
        }
      }
    }, 500);
  }

  componentWillUnmount() {
    if (this.timer) {
      window.clearInterval(this.timer);
      this.timer = undefined;
    }
  }

  // event handlers

  async onStartStopClick() {
    const session = getSession();
    if (!session) {
      await createSession();
    }
    const { isPublishing } = getPlayerState();
    if (isPublishing) {
      await stopPublishing();
      rateSession();
    } else {
      startPublishing();
    }
  }

  async onCopyToClipboard() {
    const url = window.location.href;
    await copyTextToClipboard(url);
    this.setState({ justCopied: true });
    setTimeout(() => {
      this.setState({ justCopied: false });
    }, 2000);
  }

  async onTabChange(tab: string) {
    const session = getSession();
    if (!session) {
      await createSession();
    }
    const { isPublishing } = getPlayerState();
    if (!isPublishing) {
      this.setState({ type: tab, justCopied: false });
    }
  }

  // render helpers

  renderLocal() {
    const session = getSession();
    const isLive = isSessionLive();
    const { isPublishing } = getPlayerState();
    const buttonClassName = cn('PlayerSource_button', session && !isLive && '_disabled');
    return (
      <div className={cn('PlayerSource_local', isPublishing && '_on')}>
        {/* {isPublishing ? this.renderVideo() : this.renderImage()} */}
        <div className="PlayerSource_wrapper">
          <video
            id="videoCamera"
            className="PlayerSource_video"
            style={{ opacity: `${isPublishing ? 1 : 0}` }}
          />
          <img
            className="PlayerSource_image"
            src="/img/player/publish.gif"
            alt="WebRTC Publishing"
          />
        </div>
        <button className={buttonClassName} onClick={() => this.onStartStopClick()}>
          WebRTC {isPublishing ? 'Stop' : 'Start'} Publishing
        </button>
        <span className="PlayerSource_status">
          {isPublishing ? 'Publishing is ON' : 'Publishing is OFF'}
        </span>
      </div>
    );
  }

  renderImage() {
    return (
      <img className="PlayerSource_image" src="/img/player/publish.gif" alt="WebRTC Publishing" />
    );
  }

  renderVideo() {
    return <video id="videoCamera" className="PlayerSource_video" />;
  }

  renderRemote() {
    const { justCopied } = this.state;
    const url = window.location.href;
    return (
      <div className={cn('PlayerSource_remote', justCopied && '_justCopied')}>
        <div className="PlayerSource_remoteText">
          <p>
            Here is the{' '}
            <a href={url} target="_blank" rel="noopener noreferrer">
              link
            </a>{' '}
            to the page with WebRTC webcamera player/capture. Please open a remote PC and start.
          </p>
        </div>
        <button className="PlayerSource_button" onClick={() => this.onCopyToClipboard()}>
          Copy to Clipboard
        </button>
        <span className="PlayerSource_status">{justCopied && 'Copied'}</span>
      </div>
    );
  }

  // other helpers

  typeIsLocal() {
    const { type } = this.state;
    return type === 'local';
  }
}
