import React from 'react';

import { cn } from '../../lib/utils.lib';
import { FluessonicLogo } from './FluessonicLogo';
import { SocialList } from './SocialList';

import './Header.css';

interface P {
  className?: string;
}

export class Header extends React.Component<P> {
  render() {
    const { className } = this.props;
    return (
      <div className={cn(className, 'LandingHeader')}>
        <FluessonicLogo />
        <SocialList className="LandingHeader_socialList" />
      </div>
    );
  }
}
