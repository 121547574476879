import * as api from './api.service';

export interface Session {
  token: string;
  createdAt: number;
  startedAt?: number;
  isActive: boolean;
  ttlInSeconds: number;
  videoServerHost: string;
}

export interface Ticket {
  id: number;
}

export async function createSession(): Promise<Session> {
  const { session } = await api.post('/session');
  // const { session } = await api.post(`http://localhost:8080/streamer/api/v3/streams/${token}`);
  return session;
}

export async function getSession(token: string): Promise<Session> {
  const { session } = await api.get(`/session/${token}`);
  return session;
}

export async function startSession(token: string): Promise<Session> {
  const { session } = await api.post(`/session/${token}/start`);
  return session;
}

export async function activateSession(token: string): Promise<Session> {
  const { session } = await api.post(`/session/${token}/activate`);
  return session;
}

interface RateSessionData {
  rating: number;
  name: string;
  email: string;
  phone?: string;
}

export async function rateSession(token: string, data: RateSessionData): Promise<Ticket> {
  const { ticket } = await api.post(`/session/${token}/rate`, data);
  return ticket;
}

interface RequestTrialData {
  name: string;
  email: string;
  message: string;
  sessionToken?: string;
}

export async function requestTrial(data: RequestTrialData): Promise<Ticket> {
  const { ticket } = await api.post('/request/trial', data);
  return ticket;
}
