import React from 'react';

import { cn } from '../../lib/utils.lib';

import './SocialList.css';

interface P {
  className?: string;
}

const SOCIAL_ICONS = [
  {
    href: 'https://www.youtube.com/channel/UCNoPKF6UZzZChR_ABX2Zi_w?view_as=subscriber',
    src: '/img/landing/social/youtube.svg',
    alt: 'YouTube Icon',
  },
  {
    href: 'https://www.facebook.com/flussonic.streamer/',
    src: '/img/landing/social/fb.svg',
    alt: 'Facebook Icon',
  },
  {
    href: 'https://twitter.com/flussonic',
    src: '/img/landing/social/tw.svg',
    alt: 'Twitter Icon',
  },
];

export class SocialList extends React.Component<P> {
  render() {
    const { className } = this.props;
    return (
      <ul className={cn('LandingSocialList', className)}>
        {SOCIAL_ICONS.map((x) => this.renderIcon(x))}
      </ul>
    );
  }

  // render helpers

  renderIcon(icon: { src: string; href: string; alt: string }) {
    return (
      <li className="LandingSocialList_item" key={icon.src}>
        <a
          href={icon.href}
          target="_blank"
          rel="nofollow noopener noreferrer"
          className="LandingSocialList_link"
        >
          <img alt={icon.alt} src={icon.src} />
        </a>
      </li>
    );
  }
}
