import { StreamType } from './player.service';

const STREAM_TYPE_MAP = {
  source: 'Source',
  mbr: 'MBR',
  nvr: 'NVR',
}

export function formatStreamType(streamType: StreamType) {
  return STREAM_TYPE_MAP[streamType];
}