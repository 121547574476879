import React from 'react';

import { cn } from '../../lib/utils.lib';

import './Button.css';

interface P {
  className?: string;
  text: string;
  active?: boolean;
  playing?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export class Button extends React.Component<P> {
  render() {
    const { className, text, active, playing, disabled } = this.props;
    const color = playing ? '#19b485' : '#d47fa6';
    const prefix = playing ? 'Stop' : 'Play';
    const divClassName = cn(
      'PlayerButton',
      className,
      active && '_active',
      playing && '_playing',
      disabled && '_disabled',
    );
    return (
      <div className={divClassName}>
        <button type="button" onClick={() => this.onClick()}>
          {prefix} {text}
        </button>
        <svg
          className="PlayerButton_arrow"
          xmlns="http://www.w3.org/2000/svg"
          width="84"
          height="7"
          viewBox="0 0 84 7"
        >
          <path d="M42,0,84,7H0Z" transform="translate(84 7) rotate(180)" fill={color} />
        </svg>
      </div>
    );
  }

  // event handlers

  onClick() {
    const { onClick } = this.props;
    if (onClick) {
      onClick();
    }
  }
}
