import React from 'react';

import { cn } from '../../lib/utils.lib';
import {
  getPlayerState,
  PlayerType,
  startPlaying,
  stopPlaying,
  StreamType,
} from '../../services/player.service';
import { isSessionActive, isSessionLive } from '../../services/session.service';
import { Button } from '../player/Button';

import './Step.css';

interface P {
  className?: string;
  item: { number: number; title: string; url: string; hint?: string; text: JSX.Element };
  button: { text: string; streamType: StreamType };
}

export class Step extends React.Component<P> {
  render() {
    const { className, item, button } = this.props;
    const { isPlaying, isPlayerLoading, streamType } = getPlayerState();
    const isLive = isSessionLive();
    const isActive = isSessionActive();
    const isSelected = streamType === button.streamType;
    return (
      <div className={cn('PlayerStep', className)}>
        <svg
          className="PlayerStep_arrow"
          xmlns="http://www.w3.org/2000/svg"
          width="20.866"
          height="35.732"
          viewBox="0 0 20.866 35.732"
        >
          <path
            d="M339.733-178.4,326.11-164.776l13.624,13.624"
            transform="translate(343.976 -146.91) rotate(180)"
            fill="none"
            stroke="#6c6fb6"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="6"
          />
        </svg>
        <div className="PlayerStep_content">
          <a
            className="PlayerStep_describe"
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="PlayerStep_prompt">View documentation</span>
            <span className="PlayerStep_info">step</span>
            <div className="PlayerStep_wrapper">
              <div className="PlayerStep_number">{item.number}</div>
              <div className="PlayerStep_title">
                <span>{item.title}</span>
              </div>
            </div>
            <div className="PlayerStep_text">{item.text}</div>
            {item.hint && <div className="PlayerStep_hint">{item.hint}</div>}
          </a>
          <Button
            text={button.text}
            active={isSelected}
            playing={isSelected && (isPlaying || isPlayerLoading)}
            disabled={!isLive || !isActive}
            onClick={() => this.onPlayStopClick(button.streamType)}
          />
        </div>
      </div>
    );
  }

  // event handlers

  onPlayStopClick(buttonStreamType: StreamType) {
    const { isPlaying, streamType } = getPlayerState();
    if (isPlaying && streamType === buttonStreamType) {
      stopPlaying();
    } else {
      startPlaying(
        buttonStreamType,
        buttonStreamType === StreamType.nvr ? PlayerType.hls : PlayerType.webrtc,
      );
    }
  }
}
