import React from 'react';

import { createForm, Form } from '../../services/form.service';
import { requestTrial, Ticket } from '../../services/sdk.service';
import { fetchSession, getSession, stopSessionPolling } from '../../services/session.service';
import { scrollToElement } from '../../services/ui.service';
import { Player } from '../player/Player';
import { Button } from './Button';
import { Footer } from './Footer';
import { Header } from './Header';
import { InfoBlock } from './InfoBlock';
import { ProcessingBlock } from './ProcessingBlock';

import './Landing.css';

interface S {
  form: Form;
  isFormSending: boolean;
  trialTicket?: Ticket;
}

const APPLICATIONS_LIST = [
  {
    imageUrl: '/img/landing/education.svg',
    title: 'Distant Education',
    text:
      'Deliver live teaching or training with a WebRTC based Virtual Classroom. Teach from anywhere in the world.',
  },
  {
    imageUrl: '/img/landing/meeting.svg',
    title: 'Corporate meetings',
    text:
      'Working from home? No Problem! Log into your device from anywhere and make browser-based video calls.',
  },
  {
    imageUrl: '/img/landing/videoconference.svg',
    title: 'Videoconferences',
    text:
      'With WebRTC users can join video conferences through major browsers. No download and no plug-ins required.',
  },
  {
    imageUrl: '/img/landing/webinar.svg',
    title: 'Webinars',
    text:
      'Ready for a webinar evolution? Launch better WebRTC-based webinars for browsers, native apps, and any device.',
  },
  {
    imageUrl: '/img/landing/user-generate.svg',
    title: 'User-generated content',
    text:
      'Building a WebRTC user-generated content platform has never been easier. Quickly add live video to your site in minutes.',
  },
];

export class Landing extends React.Component<{}, S> {
  constructor(props: {}) {
    super(props);
    this.state = {
      form: createForm(),
      isFormSending: false,
    };
  }

  render() {
    return (
      <div className="Landing">
        {this.renderSection1()}
        {this.renderSection2()}
        {this.renderSection3()}
        {this.renderSection4()}
        {this.renderSection5()}
        {this.renderSection6()}
        {this.renderSection7()}
        {this.renderSection8()}
        {this.renderSection9()}
        {this.renderSection10()}
        <Footer className="Landing_container" />
      </div>
    );
  }

  async componentDidMount() {
    const session = await fetchSession();
    if (session) {
      scrollToElement('.Player');
    }
  }

  componentWillUnmount() {
    stopSessionPolling();
  }

  // event handlers

  onDemo() {
    scrollToElement('.Player');
  }

  onTrial() {
    scrollToElement('.Landing_section._10');
  }

  async onSubmit() {
    let { form, isFormSending } = this.state;
    const session = getSession();
    if (isFormSending) {
      return;
    }
    form = form.trimValues();
    form = form.validateRequired('name');
    form = form.validateRequired('email');
    form = form.validateEmail('email');
    form = form.validateRequired('message');
    form = form.validateRegexp('message', /[\s\S]{10,}/, 'Too short');
    if (form.hasError()) {
      const errors = form.getErrors();
      const messages = [];
      for (const key in errors) {
        if (errors[key]) {
          const field = key.charAt(0).toUpperCase() + key.slice(1);
          messages.push(`- ${field}: ${errors[key]}`);
        }
      }
      alert(messages.join('\n'));
      return;
    }
    this.setState({ isFormSending: true });
    try {
      const trialTicket = await requestTrial({
        name: form.getValue('name'),
        email: form.getValue('email'),
        message: form.getValue('message'),
        sessionToken: session && session.token,
      });
      this.setState({ trialTicket });
    } catch (err: any) {
      alert(`Error: ${err.message}`);
    }
    this.setState({ isFormSending: false });
  }

  onFormChange(name: string, value: string) {
    const { form } = this.state;
    this.setState({ form: form.setValue(name, value) });
  }

  // render helpers

  renderSection1() {
    return (
      <section className="Landing_section _1">
        <div className="Landing_container">
          <div className="Landing_sectionImage">
            <svg viewBox="19.619 35.819 1182.64 969.694" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path
                  d="M763.483 841.733L611.894 279.52 458.386 841.733q-17.909 63.962-28.782 92.102-10.233 27.503-36.457 49.89-26.225 21.747-69.717 21.746-35.179 0-58.205-13.432-22.386-12.792-36.457-36.457-14.072-24.306-23.026-56.925-8.955-33.26-15.99-61.403L33.69 205.954q-14.072-55.006-14.071-83.788 0-36.458 25.584-61.403t63.32-24.944q51.809 0 69.718 33.26 17.909 33.259 31.341 96.582l122.805 547.51 137.518-512.324q15.351-58.845 27.504-89.546 12.153-30.701 39.655-53.087 27.504-22.387 74.835-22.387 47.971 0 74.194 23.666 26.864 23.026 37.098 50.529 10.234 27.503 27.504 90.824L889.49 713.171l122.804-547.51q8.955-42.853 16.63-67.159a112.499 112.499 0 0127.504-43.494q19.828-19.188 56.925-19.188 37.098 0 62.682 24.945 26.224 24.306 26.224 61.402 0 26.225-14.071 83.79l-156.062 631.299q-15.99 63.961-26.863 94.02-10.235 29.423-35.819 51.81-24.944 22.386-70.996 22.385-43.494 0-69.717-21.747-26.224-21.747-36.458-48.61-10.234-27.504-28.782-93.387z"
                  fill="#eaeaea"
                  data-color="1"
                ></path>
                <path
                  d="M1063.806 978.493h-29.555a20.3 20.3 0 002.048 9.076 14.498 14.498 0 005.369 5.922 14.325 14.325 0 007.416 1.993 18.564 18.564 0 004.926-.609 17.227 17.227 0 004.372-1.992 35.497 35.497 0 003.874-2.934q1.771-1.55 4.594-4.206a4.958 4.958 0 013.32-.996 5.47 5.47 0 013.764 1.273 4.55 4.55 0 011.44 3.597 9.975 9.975 0 01-1.606 4.815 19.37 19.37 0 01-4.87 5.258 27.54 27.54 0 01-8.136 4.151 34.788 34.788 0 01-11.235 1.66q-14.556 0-22.636-8.301-8.081-8.302-8.081-22.526a37.335 37.335 0 011.992-12.398 27.356 27.356 0 015.812-9.852 24.923 24.923 0 019.409-6.254 33.4 33.4 0 0112.397-2.213 29.079 29.079 0 0115.166 3.763 24.343 24.343 0 019.519 9.63 25.395 25.395 0 013.155 12.066q0 5.701-3.266 7.417-3.265 1.66-9.187 1.66zm-29.555-8.579h27.396q-.553-7.748-4.206-11.567a12.375 12.375 0 00-9.52-3.874 12.14 12.14 0 00-9.298 3.93q-3.597 3.874-4.372 11.511z"
                  fill="#eaeaea"
                  data-color="1"
                ></path>
                <path
                  d="M1101.33 930.237v23.079a34.475 34.475 0 018.69-6.753 22.924 22.924 0 0110.959-2.38 23.98 23.98 0 0113.173 3.598 23.017 23.017 0 018.8 10.35 37.629 37.629 0 013.154 16.05 42.747 42.747 0 01-1.77 12.619 29.543 29.543 0 01-5.037 9.907 23.087 23.087 0 01-8.081 6.53 23.633 23.633 0 01-10.405 2.27 25.362 25.362 0 01-6.586-.83 19.881 19.881 0 01-5.202-2.159 26.299 26.299 0 01-3.708-2.822q-1.495-1.44-3.985-4.317v1.494q0 4.262-2.048 6.476a6.86 6.86 0 01-5.203 2.158 6.579 6.579 0 01-5.147-2.158q-1.882-2.214-1.882-6.476v-65.972q0-4.594 1.827-6.918a6.265 6.265 0 015.202-2.38 6.58 6.58 0 015.369 2.269 9.564 9.564 0 011.882 6.365zm.72 44.885q0 9.021 4.096 13.892a13.638 13.638 0 0010.848 4.815 12.363 12.363 0 009.796-4.925q4.15-4.981 4.15-14.224a27.839 27.839 0 00-1.715-10.295 14.765 14.765 0 00-4.87-6.641 11.883 11.883 0 00-7.361-2.38 13.024 13.024 0 00-7.693 2.38 15.417 15.417 0 00-5.314 6.807 26.254 26.254 0 00-1.937 10.571z"
                  fill="#eaeaea"
                  data-color="1"
                ></path>
              </g>
            </svg>
          </div>
          <Header className="Landing_sectionHeader" />
          <InfoBlock
            className="Landing_sectionContent _big"
            image={{ imageUrl: '/img/landing/photo/10-04-01.png' }}
            title="WebRTC ​One-to-Many​ video broadcasting"
          >
            <p className="Landing_sectionSubtitle">Ultra-Low Latency WebRTC Live Streaming</p>
            <p>
              WebRTC is a free open-source project that enables real-time group and peer-to-peer
              communications. With Flussonic, ​building a ​WebRTC application has never been easier.
            </p>
            <Button
              className="Landing_button"
              text="Watch Live demo"
              onClick={() => this.onDemo()}
            />
          </InfoBlock>
        </div>
      </section>
    );
  }

  renderSection2() {
    return (
      <section className="Landing_section _2 Landing_container">
        <img src="/img/landing/photo/10-04-05.jpg" alt="WebRTC app" />
        <div className="Landing_sectionInfo">
          <p className="Landing_sectionText">
            A typical WebRTC app consist of a peer-to-peer connection between two nodes exchanging
            video and audio data. The two parties use a STUN or TURN server to coordinate the
            communication and traverse rewalls.
          </p>
          <p className="Landing_sectionText">
            By default, WebRTC uses UDP protocol that has very little overhead, but it doesn’t work
            very well on bursty and unreliable internet channels. The advantage of WebRTC is the
            low-latency video delivery. You can achieve{' '}
            <span style={{ color: '#0044ea' }}> ~ 300ms</span> delay from capturing the frame in the
            source and rendering it on the screen of the receiver.
          </p>
          <Button
            text="Get Started with WebRTC"
            className="Landing_button"
            onClick={() => this.onTrial()}
          />
        </div>
      </section>
    );
  }

  renderSection3() {
    return (
      <section className="Landing_section _3">
        <div className="Landing_container">
          <div className="Landing_sectionImage">
            <svg
              preserveAspectRatio="xMidYMid meet"
              viewBox="32.026 35.819 910.782 965.01"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path
                  d="M291.562 578.841H224.25v304.842q0 60.192-26.536 88.668-26.536 28.479-69.253 28.478-45.953 0-71.194-29.771-25.241-29.772-25.241-87.375V153.616q0-62.133 27.83-89.967 27.83-27.83 89.967-27.83h312.61q64.721 0 110.67 5.825 45.954 5.177 82.845 22.005a239.875 239.875 0 0178.962 53.72 230.712 230.712 0 0151.778 81.55q18.123 45.953 18.123 97.734 0 106.147-60.192 169.569-59.545 63.427-181.224 89.967 51.13 27.183 97.735 80.255a785.569 785.569 0 0182.844 113.26q36.892 59.546 56.956 108.083 20.712 47.894 20.712 66.016 0 18.77-12.298 37.539-11.65 18.122-32.36 28.477-20.713 11.003-47.895 11.003-32.361 0-54.367-15.533a146.053 146.053 0 01-38.187-38.187q-15.533-23.3-42.717-68.606L516.8 750.346q-41.422-70.548-74.43-107.438-32.361-36.892-66.017-50.483-33.656-13.592-84.786-13.592zm110.026-398.688H224.252v260.835H396.41q69.253 0 116.504-11.65 47.247-12.298 71.842-40.776 25.241-29.125 25.241-79.608 0-39.481-20.064-69.253-20.064-30.42-55.66-45.306-33.656-14.239-132.685-14.239z"
                  fill="#eaeaea"
                  data-color="1"
                ></path>
                <path
                  d="M885.602 954.083h-12.153v39.947a7.425 7.425 0 01-1.538 5.139 5.188 5.188 0 01-3.976 1.65 5.293 5.293 0 01-4.05-1.688 7.363 7.363 0 01-1.539-5.1v-39.948h-12.152a6.218 6.218 0 01-4.239-1.238 4.321 4.321 0 01-1.388-3.339 4.24 4.24 0 011.426-3.375 6.332 6.332 0 014.2-1.238h35.41a6.155 6.155 0 014.276 1.275 4.622 4.622 0 010 6.677 6.37 6.37 0 01-4.276 1.238z"
                  fill="#eaeaea"
                  data-color="1"
                ></path>
                <path
                  d="M942.807 982.516a14.616 14.616 0 01-1.313 5.702 20.494 20.494 0 01-4.051 6.001 20.906 20.906 0 01-7.09 4.764 25.483 25.483 0 01-10.052 1.838 35.127 35.127 0 01-7.914-.825 22.82 22.82 0 01-6.49-2.551 22.35 22.35 0 01-5.326-4.614 27.495 27.495 0 01-3.713-5.776 31.26 31.26 0 01-2.326-6.864 38.213 38.213 0 01-.75-7.727 34.582 34.582 0 011.913-11.89 25.388 25.388 0 015.552-8.965 24.013 24.013 0 018.44-5.702 27.328 27.328 0 0110.315-1.95 25.773 25.773 0 0111.89 2.663 21.439 21.439 0 017.99 6.602 12.915 12.915 0 012.776 7.39 4.829 4.829 0 01-1.35 3.375 4.281 4.281 0 01-3.264 1.463 4.578 4.578 0 01-3.226-1.013 14.415 14.415 0 01-2.363-3.488 16.69 16.69 0 00-5.139-6.114 12.414 12.414 0 00-7.239-2.026 13.205 13.205 0 00-10.953 5.214q-4.05 5.214-4.05 14.817a27.66 27.66 0 001.8 10.69 13.481 13.481 0 005.1 6.339 14.081 14.081 0 007.728 2.1 13.554 13.554 0 008.102-2.363 14.45 14.45 0 005.026-7.014 11.6 11.6 0 011.763-3.526 3.96 3.96 0 013.376-1.387 4.72 4.72 0 013.413 1.387 4.621 4.621 0 011.425 3.451z"
                  fill="#eaeaea"
                  data-color="1"
                ></path>
              </g>
            </svg>
          </div>
          <InfoBlock
            className="Landing_sectionContent _rotate"
            image={{ imageUrl: '/img/landing/photo/10-04-02.png' }}
            title="One-to-many streaming"
          >
            <p>
              The challenge of building a scalable one-to-many streaming application is that the
              broadcaster could not establish direct connection with every peer because it will not
              have enough bandwidth and the CPU power to maintain that many connections.
            </p>
            <p>
              The common solution is to send the video to a central server, and this server will
              send it to all the watchers. However, WebRTC was designed as a peer-to-peer protocol
              and the central server cannot just simply relay the video to the clients.
            </p>
          </InfoBlock>
        </div>
      </section>
    );
  }

  renderSection4() {
    return (
      <section className="Landing_section _4">
        <div id="webrtc-player"></div>
        <div className="Landing_container">
          <h2 className="Landing_sectionTitle">
            Flussonic Has the Perfect Solution for Your Needs!
          </h2>
        </div>
        <div className="Landing_playerContainer">
          <Player />
        </div>
      </section>
    );
  }

  renderSection5() {
    return (
      <section className="Landing_section _5 ">
        <div className="Landing_container">
          <p className="Landing_sectionText">
            The WebRTC peer (browser, mobile application) connects to Flussonic Media Server’s
            WebRTC endpoint. Flussonic transcodes the video, archives it, and delivers it via
            various streaming protocols. Flussonic streams video to end-clients using WebRTC and
            other low-latency or packet protocols.
          </p>
          <img
            src="/img/landing/photo/09-04-4.jpg"
            alt="Flussonic Has the Perfect Solution for Your Needs!"
          />
        </div>
      </section>
    );
  }

  renderSection6() {
    return (
      <section className="Landing_section _6">
        <div className="Landing_container">
          <InfoBlock
            className="Landing_sectionContent"
            image={{ imageUrl: '/img/landing/photo/09-04-0.jpg' }}
            title="Adaptive Bitrate Ingress"
          >
            <p className="InfoBlock_text">
              Flussonic has a lot of great features for carrier-grade one-to-many low latency
              broadcasting and adaptive bitrate (ABR) streaming is one of them.
            </p>
            <p className="InfoBlock_text">
              In most cases, a WebRTC source is an individual streaming video from a computer or
              laptop that is connected to the internet via residential connection or mobile network.
              These types of connections often have a high speed, but bursty nature. By default,
              WebRTC uses UDP protocol and it is possible that packets get lost along the way and
              since UDP provide no recovery for lost packets there will be interruptions in video
              broadcasting.
            </p>
            <p>
              Flussonic can force the WebRTC streamer to use TCP protocol that would retransmit lost
              packets. It would significantly improve the quality of the video in many cases.
            </p>
          </InfoBlock>
        </div>
      </section>
    );
  }

  renderSection7() {
    return (
      <section className="Landing_section _grey _7">
        <div className="Landing_container">
          <h2 className="Landing_sectionTitle">Network-Adaptive Encoding</h2>
          <img src="/img/landing/photo/10-04-04.png" alt="Network-Adaptive Encoding" />
          <p className="Landing_sectionText">
            Flussonic monitors the quality of the connection and directs the WebRTC streamer’s
            encoder to adjust the video bitrate. The smart algorithm uses the following parameters
            to match the available internet bandwidth to encoder bitrate: Min and max bitrate, step
            up and step down percentages used to recover from temporary speed drops, balance
            correction between calculated REMB and browser’s bitrate target, loss limit parameters,
            and the number of cycles before decreasing.
          </p>
          <Button
            text="Get Started with WebRTC"
            className="Landing_button"
            onClick={() => this.onTrial()}
          />
        </div>
      </section>
    );
  }

  renderSection8() {
    return (
      <section className="Landing_section _8">
        <div className="Landing_container">
          <h2 className="Landing_sectionTitle">Processing source video</h2>
          <h3>
            Flussonic Media Server offers a number of features to process ingested stream for
            further delivery
          </h3>
          <div className="Landing_sectionColumnWrapper">
            <div className="Landing_sectionColumn">
              <ProcessingBlock
                imageUrl="/img/landing/multi-bitrate.svg"
                title="Multi Bitrate Transcoding"
              >
                <p>
                  To enhance experience of the viewers, the streaming server could transcode
                  ingested video into several profiles with different resolutions and bitrates. This
                  will allow the player to detect the speed of the internet channel and select the
                  appropriate profile.
                </p>
              </ProcessingBlock>
              <ProcessingBlock imageUrl="/img/landing/logo-burn-in.svg" title="Logo Burn-in">
                <p>Flussonic Media Server could overlay any logo on top of a video stream.</p>
              </ProcessingBlock>
            </div>
            <ProcessingBlock
              className="Landing_sectionColumn"
              imageUrl="/img/landing/archive.svg"
              title="Archive"
            >
              <p>With Flussonic Media Server, you can use rich archive-related functionality:</p>
              <ul className="Landing_list">
                <li className="Landing_listItem">
                  Real time recording and viewing IP cameras without limitations on the archive size
                </li>
                <li className="Landing_listItem">
                  Maintaining the archive depth <br />
                  (for example, 1 week)
                </li>
                <li className="Landing_listItem">
                  Broadcasting HLS, HDS, MPEG-TS, RTSP, RTMP, DASH
                </li>
                <li className="Landing_listItem">
                  Broadcasting in the timeshift mode <br />
                  (for example, video can be shifted for an hour back)
                </li>
                <li className="Landing_listItem">Export of video to MP4 ﬁles</li>
                <li className="Landing_listItem">
                  Recording video to an enterprise storage or to a cloud storage such as Amazon S3
                </li>
              </ul>
            </ProcessingBlock>
          </div>
          <Button
            text="Start your Free Trial"
            className="Landing_button"
            onClick={() => this.onTrial()}
          />
        </div>
      </section>
    );
  }

  renderSection9() {
    return (
      <section className="Landing_section _9 _grey _app">
        <div className="Landing_container">
          <h2 className="Landing_sectionTitle">Applications</h2>
          <div className="Landing_appWrapper">
            {APPLICATIONS_LIST.map((x) => (
              <ProcessingBlock
                className="Landing_appBlock"
                key={x.title}
                imageUrl={x.imageUrl}
                title={x.title}
              >
                <p>{x.text}</p>
              </ProcessingBlock>
            ))}
          </div>
        </div>
      </section>
    );
  }

  renderSection10() {
    return (
      <section className="Landing_section _10">
        <div className="Landing_container">
          <div className="Landing_sectionImage">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1255.2"
              height="1029.145"
              viewBox="0 0 1255.2 1029.145"
            >
              <path
                d="M809.122,891.179,648.232,294.472,485.306,891.179q-19.008,67.886-30.548,97.753-10.861,29.191-38.694,52.951-27.834,23.081-73.994,23.08-37.337,0-61.776-14.256-23.759-13.577-38.694-38.694-14.935-25.8-24.439-60.418-9.5-35.3-16.971-65.17L34.553,216.392q-14.935-58.381-14.934-88.929,0-38.695,27.154-65.17t67.2-26.474q54.988,0,74,35.3t33.264,102.508l130.34,581.1L497.532,210.972q16.293-62.455,29.192-95.04t42.088-56.344Q598,35.828,648.238,35.828q50.914,0,78.746,25.118,28.512,24.439,39.374,53.629t29.192,96.4L942.86,754.729l130.339-581.1q9.5-45.482,17.65-71.28a119.4,119.4,0,0,1,29.192-46.163q21.044-20.365,60.418-20.365T1246.986,62.3q27.833,25.8,27.833,65.169,0,27.834-14.934,88.931L1094.247,886.427q-16.971,67.885-28.511,99.789-10.863,31.228-38.017,54.989-26.474,23.759-75.352,23.758-46.163,0-73.994-23.081t-38.7-51.592q-10.862-29.192-30.548-99.117Z"
                transform="translate(-19.619 -35.819)"
                fill="#eaeaea"
              />
            </svg>
          </div>
          <h2 className="Landing_sectionTitle">Get Started with WebRTC</h2>
          <InfoBlock
            className="Landing_sectionContent"
            image={{
              imageUrl: '/img/landing/photo/10-04-06.png',
              imageAlt: 'Get Started with WebRTC',
            }}
          >
            {this.renderForm()}
          </InfoBlock>
        </div>
      </section>
    );
  }

  renderForm() {
    const { form, isFormSending, trialTicket } = this.state;
    if (trialTicket) {
      return (
        <div>
          <h3>Thanks for your interest!</h3>
          <p>Ticket {trialTicket.id} has been created.</p>
        </div>
      );
    }
    return (
      <form className="Landing_form">
        <div className="Landing_formRow">
          <input
            className="Landing_formInput"
            type="text"
            placeholder="Name *"
            maxLength={100}
            value={form.getValue('name')}
            onChange={(e) => this.onFormChange('name', e.target.value)}
          />
          <input
            className="Landing_formInput"
            type="text"
            placeholder="Email *"
            maxLength={100}
            value={form.getValue('email')}
            onChange={(e) => this.onFormChange('email', e.target.value)}
          />
        </div>
        <textarea
          className="Landing_formTextarea"
          placeholder="Type your message here..."
          value={form.getValue('message')}
          onChange={(e) => this.onFormChange('message', e.target.value)}
        ></textarea>
        <Button
          className="Landing_button"
          text={isFormSending ? 'Sending...' : 'Submit'}
          onClick={() => this.onSubmit()}
        />
      </form>
    );
  }
}
