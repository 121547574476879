import React from 'react';

import { cn } from '../../lib/utils.lib';

import './ProcessingBlock.css';

interface P {
  className?: string;
  imageUrl: string;
  title?: string;
  children?: any;
}

export class ProcessingBlock extends React.Component<P> {
  render() {
    const { className, imageUrl, title } = this.props;
    return (
      <div className={cn('LandingProcessingBlock', className)}>
        <div className="LandingProcessingBlock_header">
          <div className="LandingProcessingBlock_image Landing_roundIcon">
            <img src={imageUrl} alt={title} />
          </div>
          <div className="LandingProcessingBlock_title _mobile">{title}</div>
        </div>
        <div className="LandingProcessingBlock_content">
          <div className="LandingProcessingBlock_title _desktop">{title}</div>
          {this.props.children}
        </div>
      </div>
    );
  }
}
