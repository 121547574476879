import validator from 'validator';

export interface FormRawValues {
  [key: string]: any;
}

export interface FormValues {
  [key: string]: string;
}

export interface FormErrors {
  [key: string]: string | undefined;
}

export interface Form {
  getValues(): FormValues;
  getErrors(): FormErrors;
  getValue(name: string): string;
  setValue(name: string, value: any): Form;
  hasError(name?: string): boolean;
  getError(name: string): string | undefined;
  setError(name: string, error: string): Form;
  resetError(name: string): Form;
  resetErrors(): Form;
  trimValues(): Form;
  validateRequired(name: string): Form;
  validateRegexp(name: string, regexp: RegExp, error?: string): Form;
  validateEmail(name: string): Form;
}

export function createForm(rawValues: FormRawValues = {}, errors: FormErrors = {}) {
  const values = prepareValues(rawValues);
  const form: Form = {
    getValues() {
      return values;
    },
    getErrors() {
      return errors;
    },
    getValue(name) {
      return values[name] || '';
    },
    setValue(name, value) {
      const newValues = { ...values, [name]: prepareValue(value) };
      return createForm(newValues, errors);
    },
    hasError(name) {
      if (name) {
        return errors[name] !== undefined;
      }
      for (const k of Object.keys(errors)) {
        if (errors[k] !== undefined) {
          return true;
        }
      }
      return false;
    },
    getError(name) {
      return errors[name];
    },
    setError(name, error) {
      const newErrors = { ...errors, [name]: error };
      return createForm(values, newErrors);
    },
    resetError(name) {
      const newErrors = { ...errors, [name]: undefined };
      return createForm(values, newErrors);
    },
    resetErrors() {
      return createForm(values, {});
    },
    trimValues() {
      const keys = Object.keys(values);
      const newValues: FormValues = {};
      for (const key of keys) {
        newValues[key] = values[key].trim();
      }
      return createForm(newValues, errors);
    },
    validateRequired(name) {
      if (values[name]) {
        return form;
      }
      const newErrors = { ...errors, [name]: 'Required' };
      return createForm(values, newErrors);
    },
    validateRegexp(name, regexp, error = 'Invalid format') {
      const value = values[name];
      if (!value) {
        return form;
      }
      if (regexp.test(value)) {
        return form;
      }
      const newErrors = { ...errors, [name]: error };
      return createForm(values, newErrors);
    },
    validateEmail(name) {
      const value = values[name];
      if (!value) {
        return form;
      }
      if (!validator.isEmail(value)) {
        return form.setError(name, 'Invalid format');
      }
      return form;
    },
  };
  return form;
}

// private

function prepareValues(rawValues: FormRawValues) {
  const values: FormValues = {};
  const keys = Object.keys(rawValues);
  for (const key of keys) {
    values[key] = prepareValue(rawValues[key]);
  }
  return values;
}

function prepareValue(rawValue: any) {
  if (rawValue === null || rawValue === undefined) {
    return '';
  }
  return String(rawValue);
}
