/* eslint-disable */

import React from 'react';

import { cn } from '../../lib/utils.lib';

import './InfoBlock.css';

interface P {
  className?: string;
  image: { imageUrl: string; imageAlt?: string };
  title?: string;
  children?: any;
}

export class InfoBlock extends React.Component<P> {
  render() {
    const { className, image, title } = this.props;
    return (
      <div className={cn('LandingInfoBlock', className)}>
        <div className="LandingInfoBlock_image">
          <img src={image.imageUrl} alt={image.imageAlt} />
        </div>
        <div className="LandingInfoBlock_description">
          {title && <h2 className="LandingInfoBlock_title">{title}</h2>}
          {this.props.children}
        </div>
      </div>
    );
  }
}
