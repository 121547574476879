import React from 'react';

import { cn } from '../../lib/utils.lib';
import { VoidLink } from '../VoidLink';

import './StarRating.css';

interface P {
  className?: string;
  onClick?: () => void;
  checked?: boolean;
}

export class StarRating extends React.Component<P> {
  render() {
    const { className, checked = false } = this.props;
    const iconClassName = cn('StarRating', className, checked && '_active');
    return (
      <VoidLink className={iconClassName} onClick={() => this.onClick()}>
        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">
          <g transform="translate(-0.087 0.2)">
            <path
              className="StarRating_star"
              d="M35.542,43.013a2.158,2.158,0,0,1-.993-.237L23.541,37.013,12.534,42.776A2.158,2.158,0,0,1,9.4,40.488l2.158-12.151L2.67,19.7a2.126,2.126,0,0,1,1.209-3.626l12.3-1.791L21.6,3.213a2.158,2.158,0,0,1,3.885,0l5.482,11.051,12.3,1.791a2.126,2.126,0,0,1,1.209,3.626l-8.892,8.633,2.158,12.151a2.136,2.136,0,0,1-2.2,2.547Z"
              transform="translate(2.359 2.312)"
              fill="#0044ea"
            />
          </g>
        </svg>
      </VoidLink>
    );
  }

  // event handlers

  onClick() {
    const { onClick } = this.props;
    if (onClick) {
      onClick();
    }
  }
}
