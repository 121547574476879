import React from 'react';

interface P {
  className?: string;
}

export class FluessonicLogo extends React.Component<P> {
  render() {
    const { className } = this.props;
    return (
      <svg
        className={className}
        style={{ width: '130px' }}
        viewBox="5.502 8.179 248.228 56.725"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M39.11 31.011a15.949 15.949 0 00-3.278-.35c-2.984 0-4.096 1.872-4.096 5.441v3.804h6.144v2.985h-6.144v21.535h-3.511V42.89H23.66l.82-2.985h3.686v-3.804c0-5.851 2.751-8.485 7.257-8.485a21.596 21.596 0 014.213.468z"
            fill="#0044ea"
            data-color="1"
          ></path>
          <path
            fill="#0044ea"
            d="M48.573 64.426v-35.99l3.512-.701v36.691h-3.512z"
            data-color="1"
          ></path>
          <path
            d="M66.954 39.965v12.757c0 6.144 1.58 9.129 6.554 9.129 3.745 0 8.251-2.692 8.251-9.655V39.965h3.511v24.461h-3.51v-4.857a9.29 9.29 0 01-8.837 5.325c-6.964 0-9.538-4.448-9.538-12.114V39.965z"
            fill="#0044ea"
            data-color="1"
          ></path>
          <path
            d="M111.392 44.822a12.717 12.717 0 00-7.022-2.224c-2.4 0-5.208.878-5.208 3.394 0 5.97 14.161 2.81 14.161 11.704 0 4.682-3.979 7.198-9.304 7.198a16.99 16.99 0 01-9.48-2.867c.644-.879 1.755-2.458 1.872-2.575a14.038 14.038 0 007.49 2.34c3.512 0 5.794-1.404 5.794-4.038 0-6.436-13.986-2.984-13.986-11.762 0-4.448 4.155-6.495 8.66-6.495a14.07 14.07 0 017.55 2.165l-.527 3.16"
            fill="#0044ea"
            data-color="1"
          ></path>
          <path
            d="M138.17 44.822a12.717 12.717 0 00-7.022-2.224c-2.4 0-5.209.878-5.209 3.394 0 5.97 14.162 2.81 14.162 11.704 0 4.682-3.979 7.198-9.304 7.198a16.99 16.99 0 01-9.48-2.867c.643-.879 1.755-2.458 1.872-2.575a14.038 14.038 0 007.49 2.34c3.511 0 5.794-1.404 5.794-4.038 0-6.437-13.987-2.984-13.987-11.762 0-4.448 4.156-6.495 8.662-6.495a14.07 14.07 0 017.549 2.165l-.527 3.16"
            fill="#0044ea"
            data-color="1"
          ></path>
          <path
            d="M161.384 64.894c-6.964 0-12.582-4.506-12.582-12.64s5.618-12.698 12.582-12.698c7.022 0 12.58 4.564 12.58 12.756 0 8.076-5.558 12.582-12.58 12.582m-.059-22.179c-4.974 0-9.011 3.394-9.011 9.539 0 6.203 4.037 9.538 9.011 9.538 5.091 0 9.07-3.335 9.07-9.48 0-6.203-3.979-9.597-9.07-9.597"
            fill="#0044ea"
            data-color="1"
          ></path>
          <path
            d="M202.984 64.426V51.668c0-6.144-1.872-9.128-6.846-9.128-3.746 0-8.251 2.692-8.251 9.655v12.23h-3.512v-24.46h3.512v4.857a9.289 9.289 0 018.836-5.326c6.964 0 9.831 4.448 9.831 12.114v12.816z"
            fill="#0044ea"
            data-color="1"
          ></path>
          <path
            fill="#0044ea"
            d="M221.522 39.965v24.461h-3.511V39.965h3.511z"
            data-color="1"
          ></path>
          <path
            d="M231.844 52.195c0-8.076 5.442-12.698 12.816-12.698a14.797 14.797 0 018.134 2.516l-.703 3.16a14.026 14.026 0 00-7.431-2.517c-5.326 0-9.363 3.336-9.363 9.48 0 6.145 3.979 9.656 9.363 9.656a13.42 13.42 0 007.431-2.575l1.639 2.81a15.734 15.734 0 01-9.07 2.867c-7.373 0-12.816-4.74-12.816-12.699"
            fill="#0044ea"
            data-color="1"
          ></path>
          <path
            d="M21.968 16.412a8.233 8.233 0 11-8.233-8.233 8.233 8.233 0 018.233 8.233"
            fill="#ff0d70"
            data-color="2"
          ></path>
        </g>
      </svg>
    );
  }
}
