import React from 'react';

import { Button } from '../landing/Button';
import { Form, createForm } from '../../services/form.service';
import { StarRating } from '../landing/StarRating';
import { Session, rateSession } from '../../services/sdk.service';

interface P {
  close: (rating?: number) => void;
  onClick: () => void;
  session: Session;
}

interface S {
  form: Form;
  isFormSending: boolean;
}

const MAX_STARS = 5;

export class MTicket extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      form: createForm(),
      isFormSending: false,
    };
  }

  render() {
    const { close } = this.props;
    return (
      <div className="Modal_dialog MTicket">
        <div className="Modal_content">
          <button type="button" className="Modal_closeButton" onClick={() => close()} />
          <div className="Modal_header">Please rate the quality</div>
          {this.renderRating()}
          {this.renderForm()}
        </div>
      </div>
    );
  }

  // event handlers

  async onSubmit() {
    const { session, close } = this.props;
    let { form } = this.state;
    form = form.trimValues();
    form = form.validateRequired('rating');
    form = form.validateRequired('name');
    form = form.validateRequired('email');
    form = form.validateEmail('email');
    if (form.hasError()) {
      const errors = form.getErrors();
      const messages = [];
      for (const key in errors) {
        if (errors[key]) {
          const field = key.charAt(0).toUpperCase() + key.slice(1);
          messages.push(`- ${field}: ${errors[key]}`);
        }
      }
      alert(messages.join('\n'));
      return;
    }
    this.setState({ isFormSending: true });
    try {
      const rating = Number(form.getValue('rating'));
      await rateSession(session.token, {
        rating,
        name: form.getValue('name'),
        email: form.getValue('email'),
        phone: form.getValue('phone'),
      });
      close(rating);
    } catch (err: any) {
      alert(`Error: ${err.message}`);
      this.setState({ isFormSending: false });
    }
  }

  onFormChange(name: string, value: string) {
    const { form } = this.state;
    this.setState({ form: form.setValue(name, value) });
  }

  onClickRating(stars: number) {
    let { form } = this.state;
    form = form.setValue('rating', stars);
    this.setState({ form });
  }

  // render helpers

  renderForm() {
    const { form, isFormSending } = this.state;
    return (
      <form className="Landing_form">
        <div className="Landing_formRow">
          <input
            className="Landing_formInput"
            type="text"
            placeholder="Name *"
            maxLength={100}
            value={form.getValue('name')}
            onChange={(e) => this.onFormChange('name', e.target.value)}
          />
          <input
            className="Landing_formInput"
            type="text"
            placeholder="Email *"
            maxLength={100}
            value={form.getValue('email')}
            onChange={(e) => this.onFormChange('email', e.target.value)}
          />
          <input
            className="Landing_formInput"
            type="text"
            placeholder="Phone"
            maxLength={100}
            value={form.getValue('phone')}
            onChange={(e) => this.onFormChange('phone', e.target.value)}
          />
        </div>
        <Button
          className="Landing_formButton"
          text={isFormSending ? 'Sending...' : 'Submit'}
          onClick={() => this.onSubmit()}
        />
      </form>
    );
  }

  renderRating() {
    const { form } = this.state;
    const rating = Number(form.getValue('rating')) || 0;
    const starRating = this.getStars();
    return (
      <div className="Modal_stars">
        {starRating.map((x) => (
          <StarRating key={x} checked={rating >= x} onClick={() => this.onClickRating(x)} />
        ))}
      </div>
    );
  }

  // other helpers

  getStars() {
    const starRating = [];
    for (let i = 1; i <= MAX_STARS; i++) {
      starRating.push(i);
    }
    return starRating;
  }
}
