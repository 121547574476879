export const LSK_SESSION_RATING_MAP = 'session_rating_map';

export function getLsItem<T>(key: string) {
  const lsKey = getLsKey(key);
  const value = localStorage.getItem(lsKey);
  if (value === null) {
    return undefined;
  }
  return JSON.parse(value) as T;
}

export function setLsItem(key: string, value: any) {
  const lsKey = getLsKey(key);
  if (value === undefined) {
    localStorage.removeItem(lsKey);
  } else {
    localStorage.setItem(lsKey, JSON.stringify(value));
  }
}

export function clearLs() {
  localStorage.clear();
}

// private

function getLsKey(key: string) {
  return `webrtc_${key}`;
}
