import React from 'react';

import { cn } from '../../lib/utils.lib';

import './ProgressBar.css';

interface P {
  className?: string;
  secondsTotal: number;
  secondsLeft: number;
}

const CIRCUMFERENCE = 340;

export class ProgressBar extends React.Component<P> {
  render() {
    const { className, secondsLeft } = this.props;
    const line = this.getBarLength();
    const styles = {
      strokeDasharray: `${CIRCUMFERENCE - line}, ${line}`,
    };
    return (
      <div className={cn('PlayerProgressBar', className)}>
        <svg height="118" width="118">
          <circle cx="58" cy="58" r="54" fill="none" stroke="#cfcfcf" strokeWidth="1" />
          <circle
            className="PlayerProgressBar_bar"
            style={styles}
            cx="58"
            cy="58"
            r="54"
            fill="none"
            strokeLinecap="round"
            stroke="#19b485"
            strokeWidth="8"
          />
        </svg>
        <span className="PlayerProgressBar_progressText">{secondsLeft} sec remaining</span>
      </div>
    );
  }

  // other helpers

  getBarLength() {
    const { secondsTotal, secondsLeft } = this.props;
    return (CIRCUMFERENCE * secondsLeft) / secondsTotal;
  }
}
