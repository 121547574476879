import React from 'react';

interface P {
  close: () => void;
}

export class MSuccess extends React.Component<P> {
  render() {
    const { close } = this.props;
    return (
      <div className="Modal_dialog MSuccess">
        <div className="Modal_content">
          <button type="button" className="Modal_closeButton" onClick={() => close()} />
          <div className="Modal_header">Thanks for your interest</div>
          <div className="Modal_check">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="103.643"
              height="94.003"
              viewBox="0 0 103.643 94.003"
            >
              <g transform="translate(2 3.243)">
                <g transform="translate(1 1)">
                  <g transform="translate(0 0)">
                    <path
                      d="M6,38.56,20.46,53.02,73.48,0"
                      transform="translate(22.92)"
                      fill="none"
                      stroke="#0044ea"
                      strokeLinecap="round"
                      strokeWidth="6"
                    />
                    <path
                      d="M86.76,43.38V77.12a9.64,9.64,0,0,1-9.64,9.64H9.64A9.64,9.64,0,0,1,0,77.12V9.64A9.64,9.64,0,0,1,9.64,0H62.66"
                      fill="none"
                      stroke="#0044ea"
                      strokeLinecap="round"
                      strokeWidth="6"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
    );
  }

  // event handlers

  onClick() {
    const { close } = this.props;
    close();
  }
}
