import { updateUi } from './ui.service';

export interface Modal {
  component: any;
  props: any;
  key: number;
}

const modals: Modal[] = [];

export function getModals() {
  return modals;
}

export function showModal<T = any>(component: any, props: any = {}): Promise<T | undefined> {
  return new Promise(resolve => {
    props.close = (result: any) => {
      modals.pop();
      updateUi();
      resolve(result);
    };
    modals.push({ component, props, key: Date.now() });
    updateUi();
  });
}
