import React from 'react';

import { cn } from '../../lib/utils.lib';
import { FluessonicLogo } from './FluessonicLogo';
import { SocialList } from './SocialList';

import './Footer.css';

interface P {
  className?: string;
}

export class Footer extends React.Component<P> {
  render() {
    const { className } = this.props;
    return (
      <footer className={cn('LandingFooter', className)}>
        <div className="LandingFooter_wrapper">
          <div className="LandingFooter_logo">
            <FluessonicLogo />
            <svg
              style={{ width: '130px' }}
              viewBox="0 0 750 140"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path
                  fill="#ff6600"
                  d="M387.595 104.636c0 16.552-13.417 29.97-29.969 29.97-16.554 0-29.97-13.418-29.97-29.97s13.416-29.97 29.97-29.97c16.552 0 29.969 13.418 29.969 29.97"
                ></path>
                <path
                  fill="#ffcc00"
                  d="M446.872 62.676c0 16.55-13.417 29.97-29.969 29.97-16.554 0-29.97-13.42-29.97-29.97 0-16.552 13.416-29.973 29.97-29.973 16.552 0 29.969 13.421 29.969 29.973"
                ></path>
                <path
                  fill="#0089cc"
                  d="M373.612 62.014c0 16.55-13.417 29.97-29.97 29.97-16.55 0-29.97-13.42-29.97-29.97 0-16.552 13.42-29.973 29.97-29.973 16.553 0 29.97 13.421 29.97 29.973"
                ></path>
                <path
                  fill="#009939"
                  d="M433.55 104.636c0 16.552-13.417 29.97-29.973 29.97-16.55 0-29.969-13.418-29.969-29.97s13.42-29.97 29.969-29.97c16.556 0 29.973 13.418 29.973 29.97"
                ></path>
                <path
                  fill="#bf0000"
                  d="M410.242 35.372c0 16.552-13.419 29.97-29.97 29.97S350.3 51.925 350.3 35.373s13.42-29.97 29.971-29.97 29.971 13.418 29.971 29.97"
                ></path>
                <path
                  fill="#fc0007"
                  d="M386.933 62.676c0 .63.055 1.248.094 1.87 13.294-3.067 23.216-14.953 23.216-29.178 0-.63-.055-1.25-.095-1.872-13.294 3.07-23.215 14.955-23.215 29.18"
                ></path>
                <path
                  fill="#1cd306"
                  d="M390.89 77.516c5.165 9.03 14.871 15.13 26.018 15.13 4.544 0 8.834-1.041 12.694-2.851-5.162-9.03-14.869-15.13-26.018-15.13-4.543 0-8.834 1.04-12.694 2.851"
                ></path>
                <path
                  fill="#0f7504"
                  d="M373.612 104.636a29.821 29.821 0 006.995 19.228c4.359-5.205 6.99-11.906 6.99-19.228s-2.631-14.023-6.99-19.228a29.821 29.821 0 00-6.995 19.228"
                ></path>
                <path
                  fill="#0c5e87"
                  d="M331.774 89.529a29.831 29.831 0 0011.866 2.454c11.037 0 20.653-5.982 25.856-14.865a29.838 29.838 0 00-11.866-2.451c-11.038 0-20.656 5.982-25.856 14.862"
                ></path>
                <path
                  fill="#6b0001"
                  d="M350.431 32.84c-.07.835-.13 1.674-.13 2.529 0 14.214 9.906 26.088 23.185 29.169a30.97 30.97 0 00.127-2.527c0-14.214-9.904-26.093-23.182-29.171"
                ></path>
                <path
                  fill="#ffffff"
                  d="M353.23 100.925h-4.688c-4.14 0-7.505-3.355-7.505-7.483V51.006c0-4.128 3.366-7.485 7.505-7.485h58.788c4.139 0 7.502 3.357 7.502 7.485v42.436c0 4.128-3.363 7.483-7.502 7.483h-20.028l-40.182 19.698 6.11-19.698z"
                ></path>
                <path
                  d="M86.72 107.6L70.215 46.386 53.501 107.6q-1.95 6.964-3.134 10.028-1.114 2.995-3.97 5.432-2.855 2.368-7.59 2.368-3.83 0-6.337-1.463-2.438-1.392-3.97-3.97-1.532-2.646-2.507-6.197-.975-3.621-1.741-6.686L7.26 38.376q-1.532-5.989-1.532-9.123 0-3.97 2.785-6.685t6.895-2.716q5.64 0 7.59 3.621 1.95 3.621 3.413 10.516l13.371 59.613L54.755 37.82q1.672-6.407 2.995-9.75 1.323-3.342 4.318-5.78 2.994-2.437 8.148-2.437 5.223 0 8.078 2.577 2.925 2.507 4.039 5.501 1.114 2.995 2.995 9.89l15.112 55.781 13.37-59.613q.976-4.666 1.811-7.312.906-2.647 2.995-4.736 2.159-2.089 6.198-2.089 4.04 0 6.825 2.716 2.855 2.646 2.855 6.686 0 2.855-1.532 9.123l-16.992 68.736q-1.741 6.964-2.925 10.237-1.114 3.203-3.9 5.64-2.716 2.438-7.73 2.438-4.736 0-7.59-2.368-2.856-2.368-3.97-5.293-1.115-2.994-3.134-10.168z"
                  fill="#333333"
                ></path>
                <path
                  d="M196.404 91.44h-37.188q.07 6.477 2.577 11.421 2.576 4.944 6.755 7.452 4.248 2.507 9.332 2.507 3.412 0 6.198-.766 2.855-.836 5.501-2.507 2.647-1.741 4.875-3.691 2.229-1.95 5.78-5.293 1.463-1.254 4.179-1.254 2.925 0 4.736 1.602t1.81 4.527q0 2.576-2.02 6.058-2.019 3.413-6.128 6.616-4.039 3.134-10.237 5.223-6.128 2.09-14.137 2.09-18.316 0-28.483-10.446-10.168-10.446-10.168-28.344 0-8.427 2.507-15.6 2.508-7.243 7.313-12.396t11.839-7.87q7.033-2.785 15.6-2.785 11.143 0 19.082 4.735 8.008 4.666 11.978 12.118 3.97 7.452 3.97 15.182 0 7.173-4.11 9.332-4.108 2.09-11.56 2.09zm-37.188-10.794h34.472q-.696-9.75-5.293-14.555-4.526-4.875-11.978-4.875-7.103 0-11.7 4.945-4.526 4.875-5.501 14.485z"
                  fill="#333333"
                ></path>
                <path
                  d="M243.621 30.72v29.04q5.362-5.571 10.934-8.496 5.571-2.995 13.789-2.995 9.471 0 16.575 4.527 7.173 4.457 11.073 13.023 3.97 8.496 3.97 20.196 0 8.635-2.229 15.878-2.159 7.173-6.337 12.466-4.179 5.293-10.168 8.217-5.92 2.856-13.092 2.856-4.388 0-8.288-1.045-3.83-1.044-6.546-2.716-2.716-1.74-4.666-3.552-1.88-1.81-5.014-5.432v1.88q0 5.363-2.577 8.149-2.576 2.716-6.546 2.716-4.039 0-6.476-2.716-2.368-2.786-2.368-8.148V31.556q0-5.78 2.298-8.705 2.368-2.995 6.546-2.995 4.388 0 6.755 2.855 2.368 2.786 2.368 8.009zm.905 56.479q0 11.351 5.154 17.48 5.223 6.059 13.65 6.059 7.173 0 12.326-6.198 5.223-6.268 5.223-17.898 0-7.521-2.16-12.953-2.158-5.432-6.127-8.357-3.97-2.995-9.263-2.995-5.432 0-9.68 2.995-4.248 2.925-6.685 8.566-2.438 5.571-2.438 13.3z"
                  fill="#333333"
                ></path>
                <path
                  d="M491.43 77.77h-7.617v34.497q0 6.812-3.003 10.034-3.003 3.223-7.837 3.223-5.2 0-8.057-3.37t-2.856-9.887V29.65q0-7.031 3.15-10.181 3.149-3.15 10.18-3.15h35.376q7.325 0 12.524.66 5.2.586 9.375 2.49 5.054 2.124 8.936 6.079 3.882 3.955 5.86 9.228 2.05 5.2 2.05 11.06 0 12.012-6.811 19.19-6.739 7.177-20.508 10.18 5.786 3.077 11.06 9.082 5.273 6.006 9.375 12.817 4.174 6.739 6.445 12.231 2.344 5.42 2.344 7.471 0 2.124-1.392 4.248-1.318 2.051-3.662 3.223-2.344 1.245-5.42 1.245-3.662 0-6.152-1.758-2.49-1.685-4.322-4.321-1.757-2.637-4.834-7.764l-8.715-14.502q-4.688-7.983-8.423-12.158-3.662-4.175-7.47-5.713t-9.596-1.538zm12.451-45.117h-20.068V62.17h19.482q7.837 0 13.184-1.318 5.347-1.392 8.13-4.615 2.856-3.296 2.856-9.008 0-4.468-2.27-7.837-2.27-3.443-6.299-5.127-3.809-1.612-15.015-1.612z"
                  fill="#333333"
                ></path>
                <path
                  d="M632.57 34.26h-23.73v78.003q0 6.738-3.003 10.034-3.003 3.223-7.764 3.223-4.834 0-7.91-3.296-3.003-3.296-3.003-9.961V34.26h-23.73q-5.566 0-8.276-2.417-2.71-2.49-2.71-6.519 0-4.174 2.783-6.591 2.856-2.417 8.203-2.417h69.141q5.64 0 8.35 2.49 2.783 2.49 2.783 6.518 0 4.029-2.783 6.519-2.783 2.417-8.35 2.417z"
                  fill="#333333"
                ></path>
                <path
                  d="M744.27 89.78q0 5.127-2.563 11.133-2.49 5.933-7.91 11.719-5.42 5.713-13.844 9.302-8.422 3.589-19.629 3.589-8.496 0-15.454-1.612-6.958-1.61-12.67-4.98-5.64-3.442-10.4-9.009-4.249-5.054-7.252-11.279-3.003-6.299-4.54-13.403-1.466-7.104-1.466-15.088 0-12.964 3.736-23.218 3.809-10.254 10.84-17.505 7.031-7.324 16.48-11.133 9.448-3.808 20.142-3.808 13.037 0 23.218 5.2t15.6 12.89q5.42 7.618 5.42 14.43 0 3.735-2.636 6.592t-6.372 2.856q-4.175 0-6.299-1.978-2.05-1.977-4.614-6.811-4.248-7.983-10.034-11.938-5.713-3.955-14.136-3.955-13.403 0-21.387 10.18-7.91 10.182-7.91 28.932 0 12.524 3.515 20.874 3.516 8.276 9.961 12.378t15.088 4.101q9.375 0 15.82-4.614 6.519-4.687 9.815-13.696 1.391-4.248 3.442-6.885 2.05-2.71 6.592-2.71 3.882 0 6.665 2.71t2.783 6.739z"
                  fill="#333333"
                ></path>
              </g>
            </svg>
          </div>
          <div className="LandingFooter_contacts">
            <a className="LandingFooter_phone" href="tel:+13023161717">
              +1 (302) 316-1717
            </a>
            <div className="LandingFooter_separate">|</div>
            <a className="LandingFooter_email" href="mailto:support@flussonic.com">
              support@flussonic.com
            </a>
          </div>
          <SocialList className="LandingFooter_socialList" />
          <div className="Landing_copyright">©2022 Flussonic, WebRTC</div>
        </div>
      </footer>
    );
  }
}
