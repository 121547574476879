import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import { cn } from '../lib/utils.lib';
import { getModals, Modal } from '../services/modal.service';
import { getHistory } from '../services/router.service';
import { registerUiUpdater } from '../services/ui.service';
import { Landing } from './landing/Landing';

import './modals/Modal.css';

export class App extends React.Component {
  render() {
    return (
      <div>
        <Router history={getHistory()}>
          <Switch>
            <Route path="/" exact>
              <Landing />
            </Route>
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </Router>
        {renderModals()}
      </div>
    );
  }

  componentDidMount() {
    registerUiUpdater(() => {
      this.forceUpdate();
    });
  }
}

function renderModals() {
  const modals = getModals();
  const n = modals.length;
  if (n === 0) {
    return null;
  }
  return (
    <div>
      {modals.map((m, i) => renderModal(m, i === n - 1))}
      <div key="backdrop" className="Modal_backdrop"></div>
    </div>
  );
}

function renderModal(modal: Modal, isVisible: boolean) {
  const { component, props, key } = modal;
  const className = cn('Modal', isVisible ? '_visible' : '');
  return (
    <div key={key} className={className}>
      {React.createElement(component, props)}
    </div>
  );
}
